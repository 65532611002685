import { useContext, useEffect, useMemo, useState } from "react";
import { editTeam, FilterType, getAllRequestedTeams } from "../../services/api";
import {
  Badge,
  Button,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { RequestedTeam } from "../../entities/RequestedTeam";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { FiEdit, FiSave, FiX } from "react-icons/fi";
import "./TeamsAdmin.css";
import { count } from "console";
import { UserContext } from "../../userContext";
import debounce from "debounce";

type SelectedTeamType = {
  [id: string]: RequestedTeam;
};

export default function TeamsAdmin() {
  const [teams, setTeams] = useState([] as RequestedTeam[]);
  const [teamToReject, setTeamToReject] = useState<RequestedTeam | null>(null);
  const [teamToApprove, setTeamToApprove] = useState<RequestedTeam | null>(
    null
  );
  const [teamToEdit, setTeamToEdit] = useState<RequestedTeam | null>(null);

  const [selectedTeams, setSelectedTeams] = useState<SelectedTeamType>({});
  const [filter, setFilter] = useState<FilterType>({
    teamType: "",
    status: "",
    userName: "",
  });

  const [pageFormLoading, setPageFormLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const { t, i18n } = useTranslation("common");

  const user = useContext(UserContext);

  const getTeams = useMemo(
    () =>
      debounce(async () => {
        setPageLoading(true);
        const data = await getAllRequestedTeams(filter);
        setTeams(data);
        setPageLoading(false);
      }, 200),
    [filter]
  );

  useEffect(() => {
    getTeams();
  }, [filter]);

  const prettyStatus = {
    open: "Offen",
    approved: "Genehmigt",
    declined: "Abgelehnt",
  };

  const teamToFormData = (team: RequestedTeam) => {
    const formData = new FormData();
    formData.append("id", team.id);
    formData.append("userLastName", team.userLastName);
    formData.append("userFirstName", team.userFirstName);
    formData.append("userMail", team.userMail);
    formData.append("teamType", team.teamType);
    formData.append("teamName", team.teamName);
    formData.append("teamReason", team.teamReason);
    formData.append("status", team.status);
    formData.append("rejectedReason", team.rejectedReason);
    return formData;
  };

  const saveTeam = async (team: RequestedTeam) => {
    setPageFormLoading(true);
    await editTeam(teamToFormData(team));
    await getTeams();
    setPageFormLoading(false);
    setTeamToEdit(null);
  };

  const approveTeams = async (team: RequestedTeam) => {
    setPageFormLoading(true);
    team.status = "approved";
    await editTeam(teamToFormData(team));
    await getTeams();
    setPageFormLoading(false);
    setTeamToApprove(null);
  };

  const approveMultipleTeams = async (teams: SelectedTeamType) => {
    setPageFormLoading(true);
    for (const team of Object.values(teams)) {
      team.status = "approved";
      await editTeam(teamToFormData(team));
    }
    await getTeams();
    setPageFormLoading(false);
    setSelectedTeams({});
  };

  const declineTeam = async (team: RequestedTeam) => {
    setPageFormLoading(true);
    team.status = "declined";
    await editTeam(teamToFormData(team));
    await getTeams();
    setPageFormLoading(false);
    setTeamToReject(null);
  };

  const handleClose = () => {
    setTeamToReject(null);
    setTeamToApprove(null);
  };

  const rejectReasons = {
    notWorkRelated: {
      short: "Arbeitskontext nicht ersichtlich",
      long: "Ein Arbeitskontext ist nicht ersichtlich.",
    },
  };

  const shortenedReason = (reason: string) => {
    return reason.length > 50 ? reason.substring(0, 50) + "..." : reason;
  };

  const singularPluralDatasets =
    Object.keys(selectedTeams).length == 1 ? "Datensatz" : "Datensätze";

  if (user?.teamsRole.search("admin") === -1) {
    return <></>;
  }

  return (
    <div className="teamsAdmin">
      <Modal
        backdrop="static"
        show={!!teamToApprove}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{teamToApprove?.teamName} genehmigen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Wollen Sie das folgende MS Team genehmigen und direkt anlegen?</p>
          <p>
            <strong>
              {teamToApprove?.teamType.toUpperCase()}-{teamToApprove?.teamName}
            </strong>
          </p>
          <p>
            {teamToApprove?.userLastName} {teamToApprove?.userFirstName}
            <br />({teamToApprove?.userMail})
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (!teamToApprove) return;
              approveTeams(teamToApprove);
              handleClose();
            }}
            disabled={pageFormLoading}
          >
            Genehmigen
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        show={!!teamToReject}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {teamToReject?.teamType.toUpperCase()}-{teamToReject?.teamName}{" "}
            ablehnen?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Ablehnungsvorlagen</Form.Label>
              <Form.Select
                onChange={(e) => {
                  if (!teamToReject) return;
                  setTeamToReject({
                    ...teamToReject,
                    rejectedReason:
                      e.target.value == "notWorkRelated"
                        ? rejectReasons[e.target.value].long
                        : "",
                  });
                }}
              >
                <option value="">Optionale Vorlage wählen</option>
                <option value="notWorkRelated">
                  {rejectReasons.notWorkRelated.short}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ablehnungsgrund</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={teamToReject?.rejectedReason}
                onChange={(e) => {
                  if (!teamToReject) return;
                  setTeamToReject({
                    ...teamToReject,
                    rejectedReason: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              if (!teamToReject) return;
              declineTeam(teamToReject);
              handleClose();
            }}
            disabled={pageFormLoading}
          >
            Ablehnen
          </Button>
        </Modal.Footer>
      </Modal>

      <h2 className="ms-3">Teams Admin</h2>
      <div className="ms-3 pt-5">
        {Object.keys(selectedTeams).length} {singularPluralDatasets} gewählt{" "}
        <Button
          size="sm"
          disabled={Object.keys(selectedTeams).length == 0 || pageFormLoading}
          onClick={() => approveMultipleTeams(selectedTeams)}
        >
          Teams genehmigen
        </Button>
      </div>
      <div className="d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-3 border-bottom">
        <span>Name: </span>
        <Form.Control
          className="ms-3"
          style={{ width: "200px" }}
          value={filter.userName}
          onChange={(e) => setFilter({ ...filter, userName: e.target.value })}
        />
        <span className="ms-3">Team Art: </span>
        <Form.Select
          value={filter.teamType}
          className="ms-3"
          style={{ width: "150px" }}
          onChange={(e) => setFilter({ ...filter, teamType: e.target.value })}
        >
          <option value="">Alle</option>
          <option value="stud">Student</option>
          <option value="project">Projekt</option>
          <option value="event">Veranstaltung</option>
          <option value="team">Sonstige</option>
        </Form.Select>
        <span className="ms-3">Status: </span>
        <Form.Select
          value={filter.status}
          className="ms-3"
          style={{ width: "200px" }}
          onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        >
          <option value="">Alle</option>
          <option value="open">Offen</option>
          <option value="approved">Genehmigt</option>
          <option value="declined">Abgelehnt</option>
        </Form.Select>
      </div>
      <Table className="table" responsive striped>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Teams Art</th>
            <th scope="col">Team Name</th>
            <th scope="col">Begründung</th>
            <th scope="col">Erstellungsdatum</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {pageLoading && teams.length == 0 && (
            <tr>
              <td colSpan={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </td>
            </tr>
          )}
          {teams.length == 0 && !pageLoading && (
            <tr>
              <td colSpan={8}>Keine Daten gefunden</td>
            </tr>
          )}
          {teams.map((team: RequestedTeam) => {
            return (
              <tr key={team.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    id={`select-${team.id}`}
                    checked={!!selectedTeams[team.id]}
                    disabled={team.status !== "open"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTeams({
                          ...selectedTeams,
                          [team.id]: team,
                        });
                      } else {
                        setSelectedTeams((prevState) => {
                          const updatedSelectedTeams = { ...prevState };
                          delete updatedSelectedTeams[team.id];
                          return updatedSelectedTeams;
                        });
                      }
                    }}
                  />
                </td>
                <td>{team.userFirstName + " " + team.userLastName}</td>
                <td>
                  {team.status == "open" && (
                    <Badge>{prettyStatus[team.status]}</Badge>
                  )}
                  {team.status == "approved" && (
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      overlay={
                        <Popover id={"approved-popover-" + team.id}>
                          <Popover.Header as="h3">
                            Genehmigt von: {team.updatedBy}
                          </Popover.Header>
                          <Popover.Body></Popover.Body>
                        </Popover>
                      }
                    >
                      <Badge bg="success">{prettyStatus[team.status]}</Badge>
                    </OverlayTrigger>
                  )}
                  {team.status == "declined" && (
                    <>
                      <OverlayTrigger
                        trigger="hover"
                        placement="right"
                        overlay={
                          <Popover id={"declined-popover-" + team.id}>
                            <Popover.Header as="h3">
                              Abgelehnt von: {team.updatedBy}
                            </Popover.Header>
                            <Popover.Body>
                              <strong>Ablehungsgrund:</strong>{" "}
                              {team.rejectedReason}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Badge bg="danger" style={{ cursor: "pointer" }}>
                          {prettyStatus[team.status]}
                        </Badge>
                      </OverlayTrigger>
                    </>
                  )}
                </td>
                <td>{team.teamType.toUpperCase()}-</td>
                <td>
                  {teamToEdit?.id == team.id && (
                    <InputGroup style={{ width: "400px" }}>
                      <Form.Control
                        type="text"
                        value={teamToEdit.teamName}
                        onChange={(e) => {
                          setTeamToEdit({
                            ...teamToEdit,
                            teamName: e.target.value,
                          });
                        }}
                      />
                      <InputGroup.Text>
                        <FiSave
                          className="me-2"
                          color="var(--bs-primary)"
                          style={{ cursor: "pointer" }}
                          onClick={() => saveTeam(teamToEdit)}
                        />
                        <FiX
                          style={{ cursor: "pointer" }}
                          onClick={() => setTeamToEdit(null)}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  )}
                  {teamToEdit?.id != team.id && (
                    <InputGroup style={{ width: "400px" }}>
                      <Form.Control
                        type="text"
                        value={team.teamName}
                        disabled
                      />
                      {team.status == "open" && (
                        <InputGroup.Text>
                          <FiEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => setTeamToEdit(team)}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  )}
                </td>
                <td>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Popover id={`tooltip-${team.id}`}>
                        <Popover.Body>
                          {team.teamType == "stud" && (
                            <div>
                              <strong>Betreuer:</strong> {team.supervisor}
                            </div>
                          )}

                          <div>
                            <strong>Begründung:</strong> {team.teamReason}
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span>{shortenedReason(team.teamReason)}</span>
                  </OverlayTrigger>
                </td>
                <td>
                  {dayjs(new Date(team.requestedAt)).format(t("dateFormat"))}
                </td>
                <td>
                  {team.status == "open" && (
                    <>
                      <div>
                        <Button
                          size="sm"
                          onClick={() => setTeamToApprove(team)}
                          className="mb-2"
                          disabled={pageFormLoading}
                        >
                          genehmigen
                        </Button>
                      </div>
                      <div>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => setTeamToReject(team)}
                          disabled={pageFormLoading}
                        >
                          ablehnen
                        </Button>
                      </div>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
